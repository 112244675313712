/**
 * @file
 * Global Variables
 */
$line-height-base-em: 2.4275em;

/* Custom typography settings for prose (See _prose.scss for explanation). */
$line-height-base-prose: 1.625; // 26/16
$line-height-base-prose-em: 1.625em;
$line-height-prose-computed: floor(($font-size-base * $line-height-base-prose)); // ~26px

/* Establish custom spacing variables based on line height to facilitate vertical rhythm */
$spacing-base:                  $line-height-base-em;
$spacing-and-half:              $spacing-base + ($spacing-base/2);
$spacing-double:                $spacing-base * 2;
$spacing-three-quarters:        $spacing-base - ($spacing-base/4);
$spacing-half:                  $spacing-base / 2;
$spacing-quarter:               $spacing-base / 4;

$spacing-prose-base:            $line-height-prose-computed;
$spacing-prose-and-half:        $spacing-prose-base + ($spacing-prose-base/2);
$spacing-prose-double:          $spacing-prose-base * 2;
$spacing-prose-three-quarters:  $spacing-prose-base - ($spacing-prose-base/4);
$spacing-prose-half:            $spacing-prose-base / 2;
$spacing-prose-quarter:         $spacing-prose-base / 4;

$content-gutter-width:          80px;

/* Black/white colour variables */
$white: #ffffff;
$black: #000000;

/* Font weights */
$bold: 700;

/* Background colour variables */
$primary-bg: #222222;
$secondary-bg: #eeeeee;

/* Text colour variables */
$primary-text: #222;
$secondary-text: #666;
$tertiary-text: #999;

/* Brand visited & focused styles */
$brand-visited: #4c2c92;
$brand-focused: #ffbf47;

/* Brand border */
$brand-border: #d5d5d5;

/* Brand gray */
$brand-gray-light: #CCCCCC;

$button-color: #ec1c23;
$link-color: #ed1c24;
$error-text-color: #b94a48;